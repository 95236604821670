section#products {
  background: #80808012;
  padding: 60px 40px 100px 40px;
}

.section-heading {
  margin-bottom: 60px;
}

img {
  max-width: 100%;
  height: 350px;
  object-fit: cover;
}

.Product-name{
  text-align: center;
  margin: 10px;
}