/* .App{
  width: 100%;
  height: 100vh;
  background: rgb(83,63,251);
  background: radial-gradient(circle, rgba(83,63,251,1) 0%, rgba(252,70,70,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1{
  color: white;
  margin: 0;
  font-size: 154px;
}

h2{
  color: antiquewhite;
  font-size: 44px;
  margin: 0;
} */

body { background: #ffffff; }